<template>
  <div>
    <Header ref="header"/>

    <div class="homeindex">
      <div class="content" style="min-height: 900px;">
        <div style="height: 30px"></div>

        <div class="view" style="margin-bottom: 10px; overflow: hidden;height: 500px;font-size: 18px;position: relative;">
          <img src="http://image.yabyy.com/home/huxi/000.png" style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);">
        </div>

        <div class="visual-item w1200" style="display: block;">

          <div class="view" >
            <!--            <el-alert-->
            <!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
            <!--                type="success"-->
            <!--                effect="dark" style="margin-bottom: 20px">-->
            <!--            </el-alert>-->

            <!--            <el-alert-->
            <!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
            <!--                type="info"-->
            <!--                effect="dark" style="margin-bottom: 20px">-->
            <!--            </el-alert>-->

            <!--            <el-alert-->
            <!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
            <!--                type="warning"-->
            <!--                effect="dark" style="margin-bottom: 20px">-->
            <!--            </el-alert>-->

<!--            <el-alert-->
<!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
<!--                type="error"-->
<!--                effect="dark" style="margin-bottom: 20px" >-->
<!--            </el-alert>-->
          </div>

          <div class="view" style="margin-bottom: 10px;">
            <img src="http://image.yabyy.com/home/huxi/001.png">
          </div>
          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img" style="width: 1200px">
                  <router-link :to="{path:'/goods',query:{id:Floor[0].id}}" target="_blank" tag="a">
                    <div class="p-img" style="width: 1200px"><img :src="Floor[0].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="view" style="margin-bottom: 10px;">
            <img src="http://image.yabyy.com/home/huxi/002.png">
          </div>
          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[1].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[1].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[2].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[2].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[3].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[3].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[4].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[4].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[5].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[5].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[6].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[6].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[7].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[7].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>


          <div class="view" style="margin-bottom: 10px;">
            <img src="http://image.yabyy.com/home/huxi/003.png">
          </div>
          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[8].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[8].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[9].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[9].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[10].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[10].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[11].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[11].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[12].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[12].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[13].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[13].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[14].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[14].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[15].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[15].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[16].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[16].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[17].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[17].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[18].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[18].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[19].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[19].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>


          <div class="view" style="margin-bottom: 10px;">
            <img src="http://image.yabyy.com/home/huxi/004.png">
          </div>
          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[20].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[20].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[21].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[21].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[22].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[22].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[23].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[23].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[24].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[24].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[25].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[25].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[26].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[26].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[27].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[27].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>


          <div class="view" style="margin-bottom: 10px;">
            <img src="http://image.yabyy.com/home/huxi/005.png">
          </div>
          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[28].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[28].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[29].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[29].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[30].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[30].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>


          <div class="view" style="margin-bottom: 10px;">
            <img src="http://image.yabyy.com/home/huxi/006.png">
          </div>
          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[31].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[31].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[32].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[32].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[33].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[33].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[34].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[34].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[35].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[35].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[36].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[36].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>

    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/header/Index'
import Footer from '@/components/footer/Index'
export default {
  name: "huxi",
  components: {
    Header,
    Footer
  },
  mounted() {
    this.$refs.header.hideCate();
  },
  data() {
    return {
      Floor:[

        {'index':0,'url':'http://image.yabyy.com/home/huxi/1010772.png','id':'1010772','search':''},      //11424	1010772	依巴斯汀片

        {'index':1,'url':'http://image.yabyy.com/home/huxi/1033291.png','id':'1033291','search':''},       //15581	1033291	鑫玺牌蜂胶糖
        {'index':2,'url':'http://image.yabyy.com/home/huxi/1023121.png','id':'1023121','search':''},     //14562	1023121	罗汉果糖(铁圆盒)
        {'index':3,'url':'http://image.yabyy.com/home/huxi/1033290.png','id':'1033290','search':''},      //15580	1033290	胖大海糖
        {'index':4,'url':'http://image.yabyy.com/home/huxi/1024503.png','id':'1024503','search':''},     //14581	1024503	金银花含片
        {'index':5,'url':'http://image.yabyy.com/home/huxi/1005374.png','id':'1005374','search':''},      //8189	1005374	草珊瑚薄荷含片
        {'index':6,'url':'http://image.yabyy.com/home/huxi/1005432.png','id':'1005432','search':''},      //8229	1005432	凉桑牌胖大海糖
        {'index':7,'url':'http://image.yabyy.com/home/huxi/1010791.png','id':'1010791','search':''},      //11434	1010791	胖大海


        {'index':8,'url':'http://image.yabyy.com/home/huxi/1007829.png','id':'1007829','search':''},      //9758	1007829	橘红丸
        {'index':9,'url':'http://image.yabyy.com/home/huxi/1005701.png','id':'1005701','search':''},      //19363	1005701	野马追片
        {'index':10,'url':'http://image.yabyy.com/home/huxi/1005224.png','id':'1005224','search':''},      //8084	1005224	复方愈创木酚磺酸钾口服溶液
        {'index':11,'url':'http://image.yabyy.com/home/huxi/1006606.png','id':'1006606','search':''},      //8957	1006606	莱阳梨膏
        {'index':12,'url':'http://image.yabyy.com/home/huxi/1002065.png','id':'1002065','search':''},      //5580	1002065	雪梨膏
        {'index':13,'url':'http://image.yabyy.com/home/huxi/1001740.png','id':'1001740','search':''},      //5314	1001740	蛇胆川贝液
        {'index':14,'url':'http://image.yabyy.com/home/huxi/1002403.png','id':'1002403','search':''},      //5854	1002403	氢溴酸右美沙芬糖浆
        {'index':15,'url':'http://image.yabyy.com/home/huxi/1006694.png','id':'1006694','search':''},      //9011	1006694	强力枇杷露
        {'index':16,'url':'http://image.yabyy.com/home/huxi/1001687.png','id':'1001687','search':''},      //18083	1001687	枇杷止咳胶囊
        {'index':17,'url':'http://image.yabyy.com/home/huxi/1012008.png','id':'1012008','search':''},      //12104	1012008	茶新那敏片
        {'index':18,'url':'http://image.yabyy.com/home/huxi/1013229.png','id':'1013229','search':''},      //12690	1013229	润肺膏
        {'index':19,'url':'http://image.yabyy.com/home/huxi/1003858.png','id':'1003858','search':''},      //6993	1003858	克咳片

        {'index':20,'url':'http://image.yabyy.com/home/huxi/1032203.png','id':'1032203','search':''},      //16481	1032203	阿咖酚散
        {'index':21,'url':'http://image.yabyy.com/home/huxi/1004399.png','id':'1004399','search':''},      //7414	1004399	风寒感冒颗粒
        {'index':22,'url':'http://image.yabyy.com/home/huxi/1016226.png','id':'1016226','search':''},      //18893	1016226	复方锌布颗粒剂
        {'index':23,'url':'http://image.yabyy.com/home/huxi/7000003.png','id':'7000003','search':''},      //20836	7000003	小柴胡颗粒
        {'index':24,'url':'http://image.yabyy.com/home/huxi/1007966.png','id':'1007966','search':''},      //9836	1007966	复方氨酚烷胺片
        {'index':25,'url':'http://image.yabyy.com/home/huxi/1002479.png','id':'1002479','search':''},      //5913	1002479	双黄连口服液
        {'index':26,'url':'http://image.yabyy.com/home/huxi/1007019.png','id':'1007019','search':''},      //9231	1007019	感冒灵胶囊
        {'index':27,'url':'http://image.yabyy.com/home/huxi/1005856.png','id':'1005856','search':''},      //8466	1005856	复方氨酚烷胺胶囊

        {'index':28,'url':'http://image.yabyy.com/home/huxi/1001732.png','id':'1001732','search':''},      //5307	1001732	辛芳鼻炎胶囊
        {'index':29,'url':'http://image.yabyy.com/home/huxi/1016331.png','id':'1016331','search':''},      //19639	1016331	通窍鼻炎片
        {'index':30,'url':'http://image.yabyy.com/home/huxi/1002694.png','id':'1002694','search':''},      //6076	1002694	鼻炎灵片

        {'index':31,'url':'http://image.yabyy.com/home/huxi/1038125.png','id':'1038125','search':''},      //18747	1038125	一次性使用医用外科口罩(单独包装)
        {'index':32,'url':'http://image.yabyy.com/home/huxi/1002771.png','id':'1002771','search':''},      //6141	1002771	一次性使用医用口罩
        {'index':33,'url':'http://image.yabyy.com/home/huxi/1015293.png','id':'1015293','search':''},      //13753	1015293	一次性使用医用口罩(耳挂式儿童型)(淮海)
        {'index':34,'url':'http://image.yabyy.com/home/huxi/1015388.png','id':'1015388','search':''},      //13805	1015388	一次性使用医用口罩(松紧式)
        {'index':35,'url':'http://image.yabyy.com/home/huxi/1015228.png','id':'1015228','search':''},      //18721	1015228	一次性使用医用外科口罩(无菌耳挂式平面形)
        {'index':36,'url':'http://image.yabyy.com/home/huxi/1039361.png','id':'1039361','search':''},      //19272	1039361	医用外科口罩(平面耳挂型)
      ]
    }
  }
}
</script>

<style scoped>
.rec-store-item .p-img {
  height: 381px;
}
</style>
